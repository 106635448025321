import React, { useEffect, useState } from 'react';
import './Home.css';
import StepperForm from './StepperForm/Stepper';


const Home = () => {
  // const [searchParams] = useSearchParams();



  // useEffect(() => {
  //   const savedUrl = localStorage.getItem('fullUrl');
  //   if (savedUrl) {
  //     console.log('Retrieved URL from localStorage:', savedUrl);
  //     setFullUrl(savedUrl);


  //     const salt = bcrypt.genSaltSync(10);


  //     let hashed = savedUrl;
  //     for (let i = 0; i < 10; i++) {
  //       hashed = bcrypt.hashSync(hashed, salt);
  //     }
  //     setHashedUrl(hashed);
  //     console.log('Hashed URL:', hashed);
  //   }
  // }, []);

  return (
    <>
      <StepperForm />
    </>
  );
}

export default Home;
