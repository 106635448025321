import "./App.css";
// import "./Home.css";
import Home from "./components/Home";
import { Route, Routes, useNavigate } from "react-router-dom";
import oktaSignInConfig from "./components/OktaSignInConfig";
import { LoginCallback, Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Auth from "./components/Auth/Auth";
import Acuant from "./components/Acuant";
import { ToastContainer } from "react-toastify";
import SuccessfulRegister from "./components/SuccessfulRegister";
import Stepper from "./components/StepperForm/Stepper";
import SelectDocument from "./components/SelectDocument/SelectDocument";
import ValidateWithLN from "./components/kba/ValidateWithLN";
import Index1 from "./components/index1";
import IdentityPlatform from "./components/platfromScreen/identityPlatform";
import Submit from "./components/Submit/Submit";
import SignInScreen from "./components/platfromScreen/SignInScreen";
import ForgotStepper from "./components/forgotpassword/ForgotStepper";
import ThreatStepper from "./components/ThreatDetection/ThreatStepper";
import AccountOverview from "./components/ThreatDetection/AccountOverview";
import DbTest from "./components/dbTest/DbTest"
import Custom404 from "./components/notfound/Custom404";
const auth = new OktaAuth(oktaSignInConfig);

const App = () => {
  const navigate = useNavigate();

  const triggerLogin = () => {
    auth.signInWithRedirect();
  };
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
    console.log(oktaSignInConfig);
  };

  const customAuthHandler = async () => {
    const previousAuthState = auth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage or authentication required
      triggerLogin();
    } else {
      // User is authenticated, redirect them to the original URI or home page
      const { pathname } = window.location;
      if (pathname === "/login") {
        // Redirect to home page or desired page after successful sign-in
        navigate("/");
      }
    }
  };

  return (
    <Security
      oktaAuth={auth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route
          path="/login/callback"
          element={<LoginCallback />}
          exact={true}
        />
        {/* <Route path='/register' element={<Register />} /> */}
        <Route path="/login" element={<Auth />} />
        <Route path="/acuant" element={<Acuant />} />
        <Route path="/index1" element={<Index1 />} />
        <Route path="/SuccessfulRegister" element={<SuccessfulRegister />} />
        <Route path="/choosePlatfrom" element={<IdentityPlatform />} />
        {/* <Route path="/register" element={<Stepper />} /> */}
        <Route path="/selectDocument" element={<SelectDocument />} />
        <Route path="/validateln" element={<ValidateWithLN />} />
        <Route path="/succesfullyRegistered" element={<Submit />} />
        {/*<Route path="/forgotpassword" element={<ForgotStepper />} />*/}
        <Route path="/signin" element={<SignInScreen />} />
        {/* <Route path="/transaction" element={<ThreatStepper />} /> */}
        <Route path="/accountOverview" element={<AccountOverview />} />
        <Route path="/dbtest" element={<DbTest />} />
        <Route path="*" element={<Custom404 />} />

      </Routes>

      <ToastContainer />
    </Security>
  );
};

export default App;
