
import axios from 'axios';

export default class LnApiservice {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    post(url, data) {
        let header = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-org-id': '12mqs9kd',
                'x-api-key': 'jwkplk69uepiiwml',
            },
        };

        return new Promise((resolve, reject) => {
            axios
                .post(`${this.baseUrl}/${url}`, data, header)
                .then((resp) => {
                    resolve(resp);
                })
                .catch((error) => {
                    console.log('Reject from lexisNexis:', error);
                    reject(error);
                });
        });
    }
}