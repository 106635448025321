import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DbTest = () => {
    const [clients, setClients] = useState([]);
    const [verificationClientId, setVerificationClientId] = useState('');
    const [password, setPassword] = useState('');
    const [verifyUrl, setVerifyUrl] = useState('');

    useEffect(() => {
        axios.get('http://localhost:5000/api/clients', { withCredentials: true })
            .then(response => {
                console.log('API Response:', response.data);  // Add this line to log the response
                if (Array.isArray(response.data)) {
                    setClients(response.data);
                } else {
                    setClients([]);
                }
            })
            .catch(error => {
                console.error('There was an error fetching the clients!', error);
            });
    }, []);

    const addClient = () => {
        axios.post('http://localhost:5000/api/clients', { verificationClientId, password, verify_url: verifyUrl }, { withCredentials: true })
            .then(response => {
                setClients([...clients, { id: response.data.id, verificationClientId, hashed_password: password, verify_url: verifyUrl }]);
                setVerificationClientId('');
                setPassword('');
                setVerifyUrl('');
            })
            .catch(error => {
                console.error('There was an error adding the client!', error);
            });
    };

    const deleteClient = (id) => {
        axios.delete(`http://localhost:5000/api/clients/${id}`, { withCredentials: true })
            .then(response => {
                setClients(clients.filter(client => client.id !== id));
            })
            .catch(error => {
                console.error('There was an error deleting the client!', error);
            });
    };

    const updateClient = (id, verificationClientId, password, verify_url) => {
        axios.put(`http://localhost:5000/api/clients/${id}`, { verificationClientId, password, verify_url }, { withCredentials: true })
            .then(response => {
                setClients(clients.map(client => (client.id === id ? { ...client, verificationClientId, hashed_password: password, verify_url } : client)));
            })
            .catch(error => {
                console.error('There was an error updating the client!', error);
            });
    };

    return (
        <div>
            <h1>Clients</h1>
            <ul>
                {clients.map(client => (
                    <li key={client.id}>
                        {client.verificationClientId} - {client.hashed_password} - {client.verify_url}
                        <button onClick={() => deleteClient(client.id)}>Delete</button>
                        <button onClick={() => updateClient(client.id, client.verificationClientId, password, client.verify_url)}>Update</button>
                    </li>
                ))}
            </ul>
            <h2>Add Client</h2>
            <input
                type="text"
                placeholder="Verification Client ID"
                value={verificationClientId}
                onChange={(e) => setVerificationClientId(e.target.value)}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <input
                type="text"
                placeholder="Verify URL"
                value={verifyUrl}
                onChange={(e) => setVerifyUrl(e.target.value)}
            />
            <button onClick={addClient}>Add Client</button>
        </div>
    );
};
export default DbTest;
