import React, { useState, useEffect } from "react";
import { Steps } from "antd";
import {
  UserOutlined,
  SolutionOutlined,
  FileDoneOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import CryptoJS from "crypto-js";
import axios from "axios";

import Registration from "../Steps/Registration";
import SelectDocument from "../SelectDocument/SelectDocument";
import ValidateWithLN from "../kba/ValidateWithLN";
import UploadFaceImage from "../UploadFaceImage/UploadFaceImage";
import Submit from "../Submit/Submit";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { ReactComponent as Logo } from "../../images/bid-logo.svg";

const StepperForm = () => {
  const [current, setCurrent] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address1, setAddress1] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [vRequired, setValueRequired] = useState(true);
  const [sdkToken, setSDKToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [isUserCreated, setIsUserCreated] = useState(false);
  const [verifyState, setVerifyState] = useState({
    isComplete: undefined,
    checkId: "",
  });
  const [showStepper, setshowStepper] = useState(false);
  const [base64FrontImageData, setBase64FrontImageData] = useState(null);
  const [base64BackImageData, setBase64BackImageData] = useState(null);
  const [base64SelfieImageData, setBase64SelfieImageData] = useState(null);

  const [showOnfidoView, setShowOnfidoView] = useState(false);
  const [identityProofing, setIdentityProofing] = useState("");
  const [flowValue, setFlowValue] = useState("");
  const [fullUrl, setFullUrl] = useState("");
  const [hashedUrl, setHashedUrl] = useState("");
  const [stateParam, setStateParam] = useState("");
  const [continueUrl, setContinueUrl] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [clients, setClients] = useState([]);
  const [verificationClientId, setVerificationClientId] = useState("");
  const [verifyUrl, setVerifyUrl] = useState("");
  const [fetchedPassword, setFetchedPassword] = useState("");
  const [fetchedVerifyUrl, setFetchedVerifyUrl] = useState("");
  const [transaction, setTransaction] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const emailParam = params.get("email");
    const stateParam = params.get("state");
    const flowValueParam = params.get("flowValue");
    const isVerifiedParam = params.get("isverified");
    const verificationClientIdParam = params.get("verificationClientId");
    const verifyUrlParam = params.get("verify_url");
    const passwordParam = params.get("password");
    const transactionParam = params.get("transaction");

    if (emailParam) setEmail(emailParam);
    if (stateParam) setStateParam(stateParam);
    if (flowValueParam) setFlowValue(flowValueParam);
    if (isVerifiedParam) setIsVerified(isVerifiedParam);
    if (verificationClientIdParam)
      setVerificationClientId(verificationClientIdParam);
    if (verifyUrlParam) setVerifyUrl(verifyUrlParam);
    if (passwordParam) setPassword(passwordParam);
    if (transactionParam) setTransaction(transactionParam);
  }, []);

  useEffect(() => {
    if (verificationClientId) {
      const cacheBuster = new Date().getTime();
      axios
        .get(
          `${process.env.REACT_APP_BASEURL}/api/client/${verificationClientId}/password?_=${cacheBuster}`
        )
        .then((response) => {
          if (response.data && response.data.hashed_password) {
            setFetchedPassword(response.data.hashed_password);
          } else {
            console.error("Hashed password is missing in response data.");
          }
        })
        .catch((error) => {
          console.error("Error fetching password:", error);
        });

      axios
        .get(
          `${process.env.REACT_APP_BASEURL}/api/client/${verificationClientId}/verify-url?_=${cacheBuster}`
        )
        .then((response) => {
          if (response.data && response.data.verify_url) {
            setFetchedVerifyUrl(response.data.verify_url);
          } else {
            console.error("Verify URL is missing in response data.");
          }
        })
        .catch((error) => {
          console.error("Error fetching verify URL:", error);
        });
    }

    const parameters = window.location.search;

    const password = fetchedPassword;

    // Ensure parameters are sorted in a consistent order
    const splitParameters = parameters
      .substring(1)
      .split("&")
      .filter((param) => !param.startsWith("flowValue"))
      .sort();
    const newParameters = splitParameters.join("&");

    const concatenatedString = password + newParameters;










    let hashedValue = concatenatedString;
    for (let i = 0; i < 10; i++) {
      hashedValue = CryptoJS.SHA256(hashedValue).toString(CryptoJS.enc.Hex);
    }

    const encodedFlowValue = encodeURIComponent(flowValue);
    setContinueUrl(
      `${fetchedVerifyUrl}?${newParameters}&flowValue=${encodedFlowValue}&hashvalue=${hashedValue}`
    );

  }, [fetchedVerifyUrl, flowValue, continueUrl]);

  const nextStep = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  let items;
  if (flowValue === "Document Verification") {
    items = [
      {
        key: "Registration",
        title: "ID Proofing",
        icon: <DocumentScannerIcon />,
      },
      {
        key: "Document Verification",
        title: "Document Verification",
        icon: <SolutionOutlined />,
      },
      {
        key: "Facial Recognition",
        title: "Facial Recognition",
        icon: <SmileOutlined />,
      },
      {
        key: "Completed",
        title: "Completed",
        icon: <FileDoneOutlined />,
      },
    ];
  } else if (flowValue === "KBA") {
    items = [
      // {
      //   key: "Registration",
      //   title: "Registration",
      //   icon: <UserOutlined />,
      // },
      {
        key: "LexisNexis InstantID Validation",
        title: "LexisNexis InstantID Validation",
        icon: <SolutionOutlined />,
      },
      // {
      //   key: "Completed",
      //   title: "Completed",
      //   icon: <FileDoneOutlined />,
      // },
    ];
  }

  return (
    <div>
      <div className="multi-step-parent-wrapper max-w-[650px] bg-white mx-auto h-full p-6 border border-neutral-300 rounded-md">
        <div className="w-full border-b">
          <Logo className="w-20 text-center mx-auto" />
        </div>
        {current > 0 && (
          <button
            className="text-blue-950 hover:bg-neutral-200 mt-4 tex-sm  border-blue-950 text-sm font-medium rounded-full p-2  bg-neutral-100"
            onClick={() => prev()}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
              />
            </svg>
          </button>
        )}
        {showStepper && (
          <Steps
            size="small"
            className="bg-white rounded-top pt-4 pb-2 border-bottom"
            current={current}
            items={items}
          />
        )}
        <div className="">
          {current === 0 && (
            <Registration
              nextStep={nextStep}
              continueUrl={continueUrl}
              setCurrent={setCurrent}
              setshowStepper={setshowStepper}
              showOnfidoView={showOnfidoView}
              identityProofing={identityProofing}
              setIdentityProofing={setIdentityProofing}
              setFlowValue={setFlowValue}
              email={email}
              setEmail={setEmail}
            />
          )}
          {current === 1 && flowValue === "Document Verification" && (
            <SelectDocument
              nextStep={nextStep}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              username={username}
              setUsername={setUsername}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              vRequired={vRequired}
              setValueRequired={setValueRequired}
              sdkToken={sdkToken}
              setSDKToken={setSDKToken}
              loading={loading}
              setLoading={setLoading}
              isUserCreated={isUserCreated}
              setIsUserCreated={setIsUserCreated}
              verifyState={verifyState}
              setVerifyState={setVerifyState}
              base64BackImageData={base64BackImageData}
              setBase64BackImageData={setBase64BackImageData}
              base64FrontImageData={base64FrontImageData}
              setBase64FrontImageData={setBase64FrontImageData}
              base64SelfieImageData={base64SelfieImageData}
              setBase64SelfieImageData={setBase64SelfieImageData}
            />
          )}
          {current === 1 && flowValue === "KBA" && (
            <ValidateWithLN
              nextStep={nextStep}
              setCurrent={setCurrent}
              firstName={firstName}
              lastName={lastName}
              email={email}
              password={password}
              zipCode={zipCode}
              address1={address1}
              phone={phone}
              city={city}
              flowValue={flowValue}
              continueUrl={continueUrl}
              country={country}
            />
          )}
          {current === 2 && flowValue === "Document Verification" && (
            <UploadFaceImage
              nextStep={nextStep}
              setCurrent={setCurrent}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              username={username}
              setUsername={setUsername}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              vRequired={vRequired}
              setValueRequired={setValueRequired}
              sdkToken={sdkToken}
              setSDKToken={setSDKToken}
              loading={loading}
              setLoading={setLoading}
              isUserCreated={isUserCreated}
              setIsUserCreated={setIsUserCreated}
              verifyState={verifyState}
              setVerifyState={setVerifyState}
              base64BackImageData={base64BackImageData}
              setBase64BackImageData={setBase64BackImageData}
              base64FrontImageData={base64FrontImageData}
              setBase64FrontImageData={setBase64FrontImageData}
              base64SelfieImageData={base64SelfieImageData}
              setBase64SelfieImageData={setBase64SelfieImageData}
              flowValue={flowValue}
              continueUrl={continueUrl}
            />
          )}
          {current === 3 && flowValue === "Document Verification" && <Submit />}
          {current === 2 && flowValue === "KBA" && <Submit />}
        </div>
        <div className="flex justify-between w-full"></div>
      </div>
    </div>
  );
};

export default StepperForm;
