
import { motion } from 'framer-motion';

const Custom404 = () => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            className="flex min-h-screen flex-col items-center justify-center bg-gray-100 text-center dark:bg-gray-900"
        >
            <motion.h1
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                className="text-6xl font-bold text-gray-800 dark:text-gray-200"
            >
                404
            </motion.h1>
            <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.5 }}
                className="mt-4 text-xl text-gray-600 dark:text-gray-400"
            >
                Oops! The page you are looking for does not exist.
            </motion.p>
            <a href="/">
                <motion.a
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1, duration: 0.5 }}
                    className="mt-6 inline-block rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800"
                >
                    Go Back Home
                </motion.a>
            </a>
        </motion.div>
    );
};

export default Custom404;
