import React, { useEffect } from "react";
import "../../components/Register/Register.css";
import "onfido-sdk-ui/dist/style.css";
import Button from "../button";

const Registration = ({
  setshowStepper,
  showOnfidoView,
  identityProofing,
  setIdentityProofing,
  setFlowValue,
  nextStep,
  email,
  setEmail,
}) => {
  useEffect(() => {
    if (email) {
      // This effect ensures the email is set in the parent state when the component mounts
      setEmail(email);
    }
  }, [email, setEmail]);

  const onNext = () => {
    nextStep();
  };

  return (
    <div className="registration-form rounded-bottom">
      
      <div className="d-flex justify-content-end"></div>
      <div className="flex flex-col">
        <div className="py-2">
          <h4 className="text-blue-950 text-2xl font-medium mb-1">
            Identity Proofing Method
          </h4>
          <p className="text-neutral-500 text-sm mb-2">
            Please select the identity proofing method to proceed.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <button
            type="button"
            className={`w-full border-2 items-center py-4 px-4 justify-start flex rounded-lg focus:outline-none focus:ring focus:ring-blue-300 hover:border-blue-800 hover:text-blue-800 hover:bg-blue-50 font-medium ${
              identityProofing === "Proceed With Document Verification"
                ? "text-blue-900 border-blue-900 bg-blue-50"
                : ""
            } ${identityProofing}`}
            onClick={() => {
              setIdentityProofing("Proceed With Document Verification");
              setshowStepper(!showOnfidoView);
              setFlowValue("Document Verification");
            }}
          >
            Document Verification{" "}
            {identityProofing === "Proceed With Document Verification" && (
              <span className="ml-auto text-blue-900">
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 8L5.7257 12.2257C6.1415 12.6415 6.82457 12.6105 7.20102 12.1588L16.5 1"
                    stroke="#1e3a8a"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
            )}
          </button>
          <button
            type="button"
            className={`w-full border-2 font-medium items-center py-4 px-4 justify-start flex rounded-lg focus:outline-none focus:ring focus:ring-blue-300 hover:border-blue-800 hover:text-blue-800 hover:bg-blue-50 ${
              identityProofing === "Proceed With KBA"
                ? "text-blue-900 border-blue-900 bg-blue-50"
                : ""
            }`}
            onClick={() => {
              setIdentityProofing("Proceed With KBA");
              setshowStepper(!showOnfidoView);
              setFlowValue("KBA");
            }}
          >
            KBA
            {identityProofing === "Proceed With KBA" && (
              <span className="ml-auto text-blue-900">
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 8L5.7257 12.2257C6.1415 12.6415 6.82457 12.6105 7.20102 12.1588L16.5 1"
                    stroke="#1e3a8a"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
            )}
          </button>
        </div>
        <div className="flex flex-col">
          {!!identityProofing && (
            <Button
              className="mt-4 md:mt-8"
              variant="primary"
              type="button"
              label={identityProofing}
              handleClick={onNext}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Registration;
