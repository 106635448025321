import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ApiService from "../../services/ApiService";
import { showErrorToast, showSuccessToast } from "../Toast";
import LottieAnimation from "../LottieAnimation";
import Button from "../button";
import LnApiservice from "../../services/LnapiService";
import ErrorComponent from "../Submit/ErrorComponent";

const ValidateWithLN = ({
  nextStep,
  firstName,
  lastName,
  email,
  password,
  zipCode,
  address1,
  phone,
  city,
  state,
  country,
  forgotStepperValue,
  continueUrl,
}) => {
  console.log("Inside ValidateWithLN");
  const [requestID, setRequestID] = useState("");
  const [questionSetIds, setQuestionSetIds] = useState("");
  const [isAnimationVisible, setAnimationVisible] = useState(false);
  const [selectedIdp, setSelectedIdp] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const apiService = new ApiService(process.env.REACT_APP_BASEURL);
  const lnapiservice = new LnApiservice(process.env.REACT_APP_BASEURL);

  function populateKBAQuestions(kbaQuestions, requestID, QuestionSetId) {
    let q = "";
    for (let i = 0; i < kbaQuestions.length; i++) {
      q += `<h5> <span class="text-sm font-bold">Question</span> ${i + 1
        }:<br/> ${kbaQuestions[i]["Text"]["Statement"]}</h5>`;
      q += `<div class="text-gray-600 text-sm mt-1">${kbaQuestions[i]["HelpText"]["Statement"]}</div>`;
      q += `<select class="w-full border border-gray-200 bg-gray-200 p-2 rounded mt-3" data-id="qid_${kbaQuestions[i]["QuestionId"]}" id="${kbaQuestions[i]["QuestionId"]}">`;
      let kq = kbaQuestions[i]["Choices"];
      for (let x = 0; x < kq.length; x++) {
        if (x === kq.length - 1) {
          // the last selection
          q += `<option selected id="${kq[x]["ChoiceId"]}" data-id="val_${kq[x]["ChoiceId"]}" value="${kq[x]["ChoiceId"]}">${kq[x]["Text"]["Statement"]}</option>`;
        } else {
          q += `<option id="${kq[x]["ChoiceId"]}" data-id="val_${kq[x]["ChoiceId"]}" value="${kq[x]["ChoiceId"]}">${kq[x]["Text"]["Statement"]}</option>`;
        }
      }
      q += "</select>";
      q += "<hr>";
    }
    // Save the question set and request ID as hidden elements in the page for the user
    // these are used when quiz is submitted
    console.log("QuestionSetID: ", QuestionSetId);
    console.log("API Reuqest ID: ", requestID);
    // q += `<input type='text' hidden readOnly id='QuestionSetId' value="${QuestionSetId}">`;
    // q += `<input type='text' hidden readOnly id='requestID' value="${requestID}">`;
    setRequestID(requestID);
    setQuestionSetIds(QuestionSetId);

    // Update the div Tags
    document.getElementById("LNKBASelections").innerHTML = q;
  }

  async function enterLNData() {
    try {
      const response = await lnapiservice.post("api/lnController/iidqa/getQuiz", {
        event_type: "init_auth",
        auth_method: "iidqa",
        account_first_name: "John",
        account_last_name: "Pprivet",
        account_address_street1: "647 NORRISTOWN RD",
        account_address_state: "VA",
        account_address_city: "HAMPTON",
        account_address_zip: "23666",
        service_type: "authentication-hub",
      });

      if (typeof response.data === "object") {
        console.log("Response from IIDQA Get Quiz API", response.data);
        // Process the response here
        const tmp = response.data; // Assuming the response data is already parsed JSON
        console.log("tmp", tmp);
        console.log("tmp.request_result:", tmp.response.request_result);
        console.log("tmp.auth_status:", tmp.response.auth_status);
        // Process the IIDQA API Response
        if (tmp.response.request_result !== "success") {
          document.getElementById("title2").innerText =
            "ERROR: LexisNexis API Failed";
          document.getElementById("sub2").innerText =
            "ERROR reason: " + tmp.response.error_detail;
        } else if (tmp.response.auth_status === "authentication_timeout") {
          console.log("authentication_timeout");
          document.getElementById("title2").innerText =
            "ERROR: LexisNexis Authentication Hub Timeout";
          document.getElementById("sub2").innerText =
            "Please try again. If the condition persists, contact your system administrator";
        } else if (tmp.response.auth_status === "authentication_error") {
          console.log("authentication_error");
          document.getElementById("title2").innerText =
            "ERROR: LexisNexis Authentication Hub Failed";
          document.getElementById("sub2").innerText =
            "ERROR reason: " + tmp.auth_status_reason;
        } else if (tmp.response.auth_status === "authentication_failure") {
          console.log("authentication_failure");
          if (tmp["iidqa.discovery_status"] !== "pass") {
            console.log(
              "Discovery Failed: Insufficient information to match a record within InstantID"
            );
            document.getElementById("title2").innerText =
              "Sorry, we failed to match an identity record";
            document.getElementById("sub2").innerText =
              "The information provided was not sufficient to match a physical data record to produce a quiz";
          } else if (tmp["iidqa.velocity_status"] !== "pass") {
            console.log("Velocity Failed: Too many quiz requests");
            document.getElementById("title2").innerText =
              "Identity Proofing Failed";
            document.getElementById("sub2").innerText =
              "There was an issue with your identity verification. Please contact support to resolve the issue.";
          } else if (tmp["iidqa.identity_event_status"] !== "pass") {
            console.log("Identity Event Failed: Too many LexisNexis requests");
            document.getElementById("title2").innerText =
              "Identity Proofing Failed";
            document.getElementById("sub2").innerText =
              "There was an issue with your identity verification. Please contact support to resolve the issue.";
          } else {
            console.log("Identity Proofing Failed: Unknown status code");
            document.getElementById("title2").innerText =
              "Identity Proofing Failed";
            document.getElementById("sub2").innerText =
              "There was an issue with your identity verification. Please contact support to resolve the issue.";
          }
        } else if (tmp.response.auth_status === "authentication_in_progress") {
          console.log("authentication_in_progress");
          const questions = tmp.response["iidqa.question_set"]["Questions"];
          console.log("Questions", questions);
          document.getElementById("title2").innerText = "Identity Verification";
          document.getElementById("sub2").innerText =
            "Please select the correct responses to continue";

          // Update UI to show questions and allow user to select responses
          populateKBAQuestions(
            questions,
            tmp.response.request_id,
            tmp.response["iidqa.question_set"]["QuestionSetId"]
          );
          document.getElementById("verifyWithLN").classList.remove("hidden");
        } else {
          console.log("Identity Proofing Failed: Unknown status code");
          document.getElementById("title2").innerText =
            "Identity Proofing Failed";
          document.getElementById("sub2").innerText =
            "There was an issue with your identity verification. Please ccontact support to resolve the issue.";
        }
      }
    } catch (error) {
      console.error("Error while calling LN API:", error);
      // Handle error scenario
    }
  }

  useEffect(() => {
    enterLNData();
  }, []);

  useEffect(() => {
    const idp = localStorage.getItem("selectedIdp");
    if (idp) {
      setSelectedIdp(idp);
    }
  }, []);

  async function verifyResponse() {
    console.log("Verify responses");

    const Questions = [];

    const questionElements = document.querySelectorAll('[data-id^="qid_"]');

    questionElements.forEach((question) => {
      const qid = question.id;
      const answerID = question.value;
      const Choices = [{ Choice: answerID }];

      const questionData = {
        QuestionId: parseInt(qid),
        Choices: Choices,
      };

      Questions.push(questionData);
    });

    try {
      const verifyResponsevar = await lnapiservice.post(
        `api/lnController/submitQuiz/${requestID}`,
        {
          "iidqa.answers": {
            QuestionSetId: questionSetIds,
            Questions,
          },
        }
      );

      console.log("verify response:", verifyResponsevar);
      if (verifyResponsevar.data.response.review_status === "pass") {
        const urlWithRequestId = `${continueUrl}&request_id=${requestID}`;
        setError(null);

        setAnimationVisible(true);
        console.log("selectedIdp", selectedIdp);
        showSuccessToast("User Successfully verified");
        // if (selectedIdp === "Okta") {
        //   if (!forgotStepperValue) {
        //     await createOktaUser();
        //   } else {
        //     showSuccessToast("User Successfully verified");
        //   }
        // } else if (selectedIdp === "Auth0") {
        //   if (!forgotStepperValue) {
        //     await createAuth0User();
        //   } else {
        //     showSuccessToast("User Successfully verified");
        //   }
        // }
        // if (!forgotStepperValue) {
        //   showSuccessToast("User Successfully created in " + selectedIdp);
        // }

        nextStep();
        setTimeout(() => {
          window.top.location.href = urlWithRequestId;
        }, 3000);

        // setTimeout(() => {
        //   if (!forgotStepperValue) {
        //     navigate("/login", { state: { selectedIdp } });
        //   }
        // }, 3000);
      }
    } catch (error) {
      setError("Failed to verify user. Please try again.");
      console.error("Error while calling LN API:", error);
      // Handle error scenario
    }
  }

  // const createOktaUser = () => {
  //   return new Promise((resolve, reject) => {
  //     const user = {
  //       profile: {
  //         firstName: firstName,
  //         lastName: lastName,
  //         email: email,
  //         login: email,
  //         zipCode: zipCode,
  //         streetAddress: address1,
  //         mobilePhone: phone,
  //         city: city,
  //         state: state,
  //         countryCode: country,
  //         LexisNexis: "LexisNexis",
  //       },
  //       credentials: {
  //         password: {
  //           value: password,
  //         },
  //       },
  //       groupIds: ["00ghey9da1PXRVcQI4x7"],
  //     };

  //     apiService
  //       .post("api/create", user)
  //       .then((resp) => {
  //         console.log("User Created:", resp);

  //         // Resolve with the response
  //         resolve(resp);
  //       })
  //       .catch((err) => {
  //         console.log("Error While creating Okta user ", err);

  //         if (
  //           err.response &&
  //           err.response.data &&
  //           err.response.data.message &&
  //           err.response.data.message.errorCauses[0] &&
  //           err.response.data.message.errorCauses[0].errorSummary
  //         ) {
  //           const errorSummary =
  //             err.response.data.message.errorCauses[0].errorSummary;
  //           console.log("Error Summary:", errorSummary);
  //           if (
  //             errorSummary.includes(
  //               "login: An object with this field already exists"
  //             )
  //           ) {
  //             showErrorToast("User Already exists");
  //             setAnimationVisible(false);
  //           } else {
  //             showErrorToast(errorSummary);
  //             // Handle other errors accordingly
  //           }
  //         } else {
  //           console.log("Failed to create user. Please try again later.");
  //         }

  //         // Reject with the error
  //         reject(err);
  //       });
  //   });
  // };

  // const createAuth0User = () => {
  //   return new Promise((resolve, reject) => {
  //     const userData = {
  //       email: email,
  //       user_metadata: {
  //         zipCode: zipCode,
  //         streetAddress: address1,
  //         mobilePhone: phone,
  //         city: city,
  //         state: state,
  //         countryCode: country,
  //       },
  //       name: `${firstName} ${lastName}`,
  //       connection: "Username-Password-Authentication",
  //       password: password,
  //       verify_email: true,
  //     };
  //     apiService
  //       .post("api/createauth0", userData)
  //       .then((response) => {
  //         console.log("User Created:", response);
  //         // Resolve with the response
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         console.log("Error While creating Auth0 user ", error);
  //         console.log(
  //           "Response data message:",
  //           error.response.data.message.body
  //         );
  //         var jsonString = error.response.data.message.body;
  //         console.log("jsonString", jsonString);
  //         var jsonObject = JSON.parse(jsonString);

  //         // Access the value of the "message" property
  //         var message = jsonObject.message;
  //         console.log("message:", message);

  //         if (message === "The user already exists.") {
  //           showErrorToast(message);
  //           setAnimationVisible(false);
  //         } else {
  //           var parts = message.split(":");
  //           var descriptiveMessage = parts[1].trim();
  //           showErrorToast(descriptiveMessage);
  //           setAnimationVisible(false);
  //         }
  //       });
  //   });
  // };

  return (
    <>
      {isAnimationVisible && <LottieAnimation />}
      <div className="bg-gray-100 p-4 my-4 text-center">
        <h2
          className="multisteps-form__title text-gray-500 mb-1 text-xl"
          id="title2"
        >
          Verifying.. please wait
        </h2>
        {error && <ErrorComponent message={error} />}
        <div className="text-sm text-gray-700" id="sub2">
          &nbsp;
        </div>
      </div>
      <div id="LNKBASelections" className="id-question"></div>
      <Button
        id="verifyWithLN"
        handleClick={verifyResponse}
        label="Verify with Lexis"
        type="primary"
        className="w-full mt-4 hidden"
      />
    </>
  );
};
export default ValidateWithLN;
