import React from "react";

const ErrorComponent = ({ message }) => {
    return (
        <div className="error-component p-2 text-sm bg-red-100 text-red-700 border border-red-400 rounded">
            <p>{message}</p>
        </div>
    );
};

export default ErrorComponent;