const Button = ({
  variant = "primary",
  label = "Button",
  className = "",
  id = "",
  handleClick,
  isDisabled = false,
}) => {
  const onClickFunction = () => {
    if (handleClick) {
      handleClick();
    }
  };
  switch (variant) {
    case "primary":
      return (
        <button
          onClick={() => {
            if (!isDisabled) {
              onClickFunction();
            }
          }}
          className={`${className} py-2 md:py-3 px-4 md:px-4 transition-colors duration-200 border-2 border-blue-950 ${isDisabled
            ? "bg-neutral-100 border text-gray-400  border-gray-200  cursor-not-allowed"
            : "bg-blue-950 hover:bg-blue-900 hover:border-blue-900  focus:ring focus:ring-blue-800"
            }   rounded focus:outline-none  text-white disabled:bg-neutral-400 `}
          id={id}
        >
          {label}
        </button>
      );
    case "secondary":
      return (
        <button
          onClick={() => {
            if (!isDisabled) {
              onClickFunction();
            }
          }}
          className={`py-2 md:py-3 px-4 md:px-4 transition-colors duration-200 border-2 border-neutral-200  ${isDisabled
            ? "bg-white text-neutral-400 focus:ring focus:ring-neutral-300 cursor-not-allowed hover:bg-neutral-100"
            : "text-neutral-500 border-neutral-400 hover:bg-blue-900 hover:border-blue-900 hover:text-white focus:ring-neutral-200 "
            }  rounded focus:outline-none focus:ring  ${className}`}
        >
          {label}
        </button>
      );
    default:
      return null;
  }
};
export default Button;
