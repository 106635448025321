import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useOktaAuth } from "@okta/okta-react";
import { Link } from "react-router-dom";
import NavbarCom from "../NavbarCom";
import Footer from "../Footer";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";

const AccountOverview = () => {
  const [userInfo, setUserInfo] = useState(null);
  const { user, isAuthenticated, logout } = useAuth0();
  const [selectedIdp, setSelectedIdp] = useState("");
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    const idp = localStorage.getItem("selectedIdp");
    if (idp) {
      setSelectedIdp(idp);
    }
  }, []);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          setUserInfo(info);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]);

  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const getUserName = () => {
    if (selectedIdp === "Auth0" && isAuthenticated && user) {
      const firstName = capitalize(user.given_name);
      const lastName = capitalize(user.family_name);
      return `${firstName} ${lastName}`.trim();
    } else if (
      selectedIdp === "Okta" &&
      authState?.isAuthenticated &&
      userInfo
    ) {
      const firstName = capitalize(userInfo.FirstName);
      const lastName = capitalize(userInfo.LastName);
      return `${firstName} ${lastName}`.trim();
    }
    return "Guest";
  };

  return (
    <div>
      <NavbarCom />
      <div className="p-6 bg-neutral-200">
        <div className="container flex flex-col gap-6">
          <h2 className="text-slate-900 text-2xl font-medium">
            Account Details
          </h2>
          <div className="bg-white p-4 rounded-lg">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <p className="text-slate-900 text-base font-normal">
                  Account Name
                </p>
                <p className="text-slate-900 text-base font-semibold">
                  {getUserName()}
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-slate-900 text-base font-normal">
                  XYZ Bank Savings A/C (USD)
                </p>
                <p className="text-slate-900 text-base font-semibold">
                  766666211612
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-slate-900 text-base font-normal">
                  Available Balance:
                </p>
                <p className="text-slate-900 text-base font-semibold">$1000</p>
              </div>
            </div>
            <div className="flex w-full gap-2 mt-4">
              <Link
                to="/transaction"
                className="px-6 py-4 bg-slate-900 rounded justify-center items-center gap-4 inline-flex hover:bg-blue-900 transition-colors duration-200"
              >
                <p className="text-white flex gap-2">
                  <SendOutlinedIcon />
                  Fund Transfer
                </p>
              </Link>
              {/* Uncomment and adjust the below Link if needed */}
              {/* <Link
                to="/statement"
                className="px-6 py-4 bg-white text-slate-900 rounded justify-center items-center gap-4 inline-flex border border-slate-900 transition-colors duration-200"
              >
                <p className="flex gap-2">
                  <ReceiptLongOutlinedIcon />
                  View Statement
                </p>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AccountOverview;
